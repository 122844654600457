.bot-update-modal {
  .modal-header {
    padding: 20px 25px;

    .modal-title {
      font-size: 18px;
      font-family: 'helvetica-bold';
      font-weight: 700;
      color: var(--text-color);
    }
  }

  .modal-body {
    padding: 5px 20px 20px;
    text-align: left;

    .form-label {
      margin-left: 3px;
    }
  }

  .modal-footer {
    margin-top: 0;
    margin-bottom: 40px;
    gap: 10px 25px;

    .close-btn {
      min-width: 125px;
    }

    .update-btn {
      min-width: 125px;
    }
  }
}

.bot-table {
  .bot-status {
    text-transform: capitalize;
  }
}