.feature-wrapper {
  padding-block: 25px;
  color: var(--text-color);
  .slick-arrow::before {
    color: var(--primary-color);
    opacity: 1;
  }

  .feature-img {
    padding-right: 10px;
    position: relative;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;
    }
    .close {
      position: absolute;
      top: 0px;
      right: 10px;
      width: 20px;
      height: 20px;
      padding: 0;
      font-size: $fs-12;
      border-radius: 4px;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: var(--primary-color) !important;
        color: var(--white-color) !important;
      }
    }
    &:hover {
      .close {
        opacity: 1;
      }
    }
  }

  .form-group {
    margin-bottom: 0;
    margin-top: 20px;
  }

  .description-message {
    font-size: 16px !important;
    height: 150px !important;
    padding-block: 15px !important;
  }

  @media screen and (max-width: 767px) {
    .slick-arrow::before {
      display: none;
    }

    .feature-img {
      img {
        height: 150px;
      }
    }
  }
}
