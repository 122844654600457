// Colors
body {
  --error-color: #e56f70;
  --secondary-100: #f2f4f7;
  --secondary-200: #e4e6eb;
  --secondary-300: #d2d5d9;
  --secondary-400: #a7acb4;
  --secondary-500: #757982;
  --secondary-600: #50545d;
  --secondary-700: #323842;
  --secondary-800: #23272e;
  --gray-100: #b2bfd2;
  --gray-200: #8292ad;
  --gray-medium: #8392ab;
  --white-color: #ffffff;

  --boxes-color: #ffffff;

  --primary-color: #54287d;
  --primary-color-light: #e1c1ff;

  &.light {
    --theme-color: #eef3fb;
    --primary-color: #082553;
    --primary-color-light: #c0d6f8;
    --boxes-color: #ffffff;
    --heading-color: #1f2835;
    --text-color: #18263b;
    --secondary-text-color: #4b6ba2;
    --input-color: #ffffff33;
    --input-select-background-color: #6f6f6f;
    --input-background-color: #ededed;
    --input-disable-background-color: #d7dee1;
    --breadcrumb-item-active-color: #ffffff;
  }

  &.dark {
    --theme-color: #082553;
    --primary-color: #14376f;
    --primary-color-light: #f3d3ff42;
    --boxes-color: #011235;
    --heading-color: #fff;
    --boxes-color: #00183d;
    --text-color: #f1f1f1;
    --secondary-text-color: #c9c9c9;
    --input-color: #ffffff33;
    --input-select-background-color: #011235;
    --input-background-color: #35416c;
    --input-disable-background-color: #263058;
    --breadcrumb-item-active-color: #d6d6d6;
  }
}

$white: #ffffff;
$black: #000000;

$primary-100: #e7f0ff;
$primary-200: #a6c8ff;
$primary-300: #5090f6;
$primary-400: #045de9;
$primary-500: #235ce9;
$primary-600: #174a9c;
$primary-700: #0e3778;
$primary-800: #253459;
$primary-900: #0c264f;

$purple-100: #d4ccff;
$purple-200: #978ae6;
$purple-300: #6454bf;
$purple-400: #483a99;
$purple-500: #2b2166;

$red-100: #ffcccc;
$red-200: #e68a8a;
$red-300: #e64343;
$red-400: #993a3a;
$red-500: #662121;

$green-100: linear-gradient(0deg,
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75)),
  #36b37e;
$green-200: linear-gradient(0deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)),
  #36b37e;
$green-300: linear-gradient(0deg,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25)),
  #36b37e;
$green-400: #36b37e;
$green-500: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
  #36b37e;
$green-600: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  #36b37e;
$green-700: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
  #36b37e;

$orange-100: linear-gradient(0deg,
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0.75)),
  #ffab00;
$orange-200: linear-gradient(0deg,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)),
  #ffab00;
$orange-300: linear-gradient(0deg,
    rgba(255, 255, 255, 0.25),
    rgba(255, 255, 255, 0.25)),
  #ffab00;
$orange-400: #ffab00;
$orange-500: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
  #ffab00;
$orange-600: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  #ffab00;
$orange-700: linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
  #ffab00;

// Font
$primary-font: 'Helvetica-Regular';

$fw-300: 300; // Light
$fw-400: 400; // Regular
$fw-500: 500; // Medium
$fw-600: 600; // Semi bold
$fw-700: 700; // Bold
$fw-900: 900; // Black

$fs-10: 10px;
$fs-11: 11px;
$fs-12: 12px;
$fs-13: 13px;
$fs-14: 14px;
$fs-15: 15px;
$fs-16: 16px;
$fs-18: 18px;
$fs-20: 20px;
$fs-22: 22px;
$fs-24: 24px;
$fs-26: 26px;
$fs-28: 28px;
$fs-30: 30px;

// Media query breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 767px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px) !default;