.monetization-ticket-view-modal {
    .modal-header {
        padding: 15px 25px 25px;

        .modal-title {
            color: var(--text-color);
        }

        button {
            background-color: var(--white-color);
            border-radius: 50%;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .modal-body {
        padding: 15px 25px 25px;
        text-align: unset;

        .form-group {
            .form-label {
                font-size: 14px;
                text-align: left;
                font-weight: 500;
                letter-spacing: 0.2px;
                color: var(--text-color) !important;
                line-height: 1.83;
                margin-bottom: 8px;
            }
        }
    }
}

body.dark {
    .monetization-ticket-view-modal {
        .modal-header {
            button {
                box-shadow: 0px 0px 30px 0px rgba(43, 43, 43, 0.3137254902);
            }
        }
    }
}