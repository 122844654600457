.report-ticket-id {
    color: var(--text-color);
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;

    span {
        font-family: 'helvetica-bold';
        font-weight: 700;
        margin-left: 3px;
    }
}

.report-form {
    margin-bottom: 15px;
}


.report-comments-box {
    .report-comments-title {
        font-size: 18px;
        color: var(--text-color);
        margin-bottom: 20px;
        padding-bottom: 7px;
        border-bottom: 1px solid var(--secondary-500);
    }

    .report-comment-user {
        padding-right: 10px;
        color: var(--text-color);
        text-align: center;

        svg {
            font-size: 24px;
        }

        .report-comment-user-name {
            font-size: 12px;
        }
    }

    .report-comment-message {
        background-color: var(--primary-color);
        color: var(--secondary-100);
        width: fit-content;
        padding: 7px 15px;
        font-size: 12px;
        position: relative;
        border-radius: 5px;
        margin-bottom: 5px;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -8px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid var(--primary-color);
            z-index: 1;
        }
    }

    .report-comment-state,
    .report-comment-date {
        font-size: 12px;
        color: var(--text-color);
        text-transform: capitalize;
    }
}