.prototype {
    .data-table {
        .table-responsive-xl {
            table {
                tbody {
                    tr {
                        td {
                            .dropdown-datatable {
                                .dropdown-datatable-menu {
                                    .schedule-item:hover {
                                        color: rgb(0, 139, 170);
                                    }

                                    .view-item:hover {
                                        color: #21bb21;
                                    }

                                    .update-item:hover {
                                        color: rgb(241, 153, 0);
                                    }

                                    .delete-item:hover {
                                        color: red
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}