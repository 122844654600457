.finance-datatable{
    .dropdown-datatable-menu{
        .dropdown-datatable-items{
            &.view:hover{
                color: #f19900 !important;
            }
            &.approved:hover{
                color: #21bb21 !important;
            }
            &.rejected:hover{
                color: var(--error-color) !important;
            }
        }
    }
}