.upload_box {
  // cursor: pointer;

  .form-group {
    position: relative;
    margin: 0;

    .form-control {
      position: absolute;
      top: 0;
      content: '';
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      z-index: 1;
    }
  }

  .slick-dots {
    bottom: -35px;
  }

  .form-label {
    font-size: $fs-12;
    font-weight: $fw-500;
    letter-spacing: 0.2px;
    color: var(--text-color);
    line-height: 1.83;
    margin-bottom: 8px;
  }

  .close_media {
    background-color: rgb(245 247 248);
    box-shadow: inset 0 0 0 0 var(--white-color);
    height: 143px;
    border-radius: 10px;
    display: block;
    width: 100%;

    label {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 0;

      .icon {
        max-width: 40px;
        height: auto;
        width: 100%;
        margin-bottom: 10px;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: var(--text-color);
      }
    }

    .slick-list {
      .slick-slide {
        position: relative;
        cursor: pointer;
      }
    }

    // .slick-dots {
    //   bottom: -12px;
    // }
    &.error {
      border: 1px solid var(--error-color);
    }
  }

  &_header {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
    align-items: center;

    .add {
      border-radius: 5px;
      font-size: $fs-12;
      padding: 2px 5px;
      width: 20px;
      height: 20px;
    }

    .form-label {
      margin-bottom: 0;
    }
  }

  .form-group:hover {
    input {
      z-index: auto;
    }
  }

  &_card:hover,
  .form-group:hover {
    .close {
      opacity: 1;
    }
  }

  &_card {
    video,
    img {
      object-fit: cover;
      height: 143px;
      width: 100%;
      border-radius: 10px;
    }

    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      width: 20px;
      height: 20px;
      padding: 0;
      font-size: $fs-12;
      border-radius: 4px;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      z-index: 2;

      &:hover {
        background-color: var(--primary-color) !important;
        color: var(--white-color) !important;
      }
    }
  }

  &_active {
    margin-bottom: 45px;
  }

  @media screen and (min-width: 1199px) {
    .close_media {
      height: 190px;
    }

    &_card {
      img,
      video {
        height: 190px;
      }
    }
  }
}
