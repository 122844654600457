@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot');
  src: url('../../fonts/icomoon.eot#iefix') format('embedded-opentype'), url('../../fonts/icomoon.ttf') format('truetype'),
    url('../../fonts/icomoon.woff') format('woff'), url('../../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-calendar:before {
  content: '\e93b';
}
.icon-double-arrow-top:before {
  content: '\e900';
}
.icon-copy:before {
  content: '\e901';
}
.icon-campaign:before {
  content: '\e902';
}
.icon-visibility-off:before {
  content: '\e903';
}
.icon-visibility:before {
  content: '\e904';
}
.icon-add:before {
  content: '\e905';
}
.icon-arrow-drop-down:before {
  content: '\e906';
}
.icon-arrow-drop-up:before {
  content: '\e907';
}
.icon-chevron-left:before {
  content: '\e908';
}
.icon-chevron-right:before {
  content: '\e909';
}
.icon-close:before {
  content: '\e90a';
}
.icon-create:before {
  content: '\e90b';
}
.icon-dashboard:before {
  content: '\e90c';
}
.icon-delete:before {
  content: '\e90d';
}
.icon-download:before {
  content: '\e90e';
}
.icon-feed:before {
  content: '\e90f';
}
.icon-filter-list:before {
  content: '\e910';
}
.icon-help:before {
  content: '\e911';
}
.icon-home:before {
  content: '\e912';
}
.icon-notifications:before {
  content: '\e913';
}
.icon-settings:before {
  content: '\e914';
}
.icon-sidebar:before {
  content: '\e915';
}
.icon-sort:before {
  content: '\e916';
}
.icon-account:before {
  content: '\e917';
}
.icon-check:before {
  content: '\e918';
}
.icon-chevron-down:before {
  content: '\e919';
}
.icon-chevron-up:before {
  content: '\e91a';
}
.icon-lock:before {
  content: '\e91b';
}
.icon-logout:before {
  content: '\e91c';
}
.icon-add-photo:before {
  content: '\e91d';
}
.icon-id-card:before {
  content: '\e91e';
}
.icon-image:before {
  content: '\e91f';
}
.icon-info:before {
  content: '\e920';
}
.icon-account-fill:before {
  content: '\e921';
}
.icon-add-circle:before {
  content: '\e922';
}
.icon-upload:before {
  content: '\e923';
}
.icon-comment:before {
  content: '\e924';
}
.icon-image-1:before {
  content: '\e925';
}
.icon-send:before {
  content: '\e926';
}
.icon-language:before {
  content: '\e927';
}
.icon-comment-rounded:before {
  content: '\e928';
}
.icon-dots-verticle:before {
  content: '\e929';
}
.icon-lock-close:before {
  content: '\e92a';
}
.icon-more-horizontal:before {
  content: '\e92b';
}
.icon-forum:before {
  content: '\e92c';
}
.icon-add-circle1:before {
  content: '\e92d';
}
.icon-cancel:before {
  content: '\e92e';
}
.icon-remove:before {
  content: '\e92f';
}
.icon-schedule:before {
  content: '\e930';
}
.icon-amp:before {
  content: '\e931';
}
.icon-desktop:before {
  content: '\e932';
}
.icon-mobile:before {
  content: '\e933';
}
.icon-tablet:before {
  content: '\e934';
}
.icon-bat:before {
  content: '\e935';
}
.icon-wk:before {
  content: '\e936';
}
.icon-merge:before {
  content: '\e937';
}
.icon-baseball:before {
  content: '\e938';
}
.icon-share:before {
  content: '\e939';
}
.icon-file:before {
  content: '\e93a';
}
.icon-refresh:before {
  content: '\ea2e';
}
.icon-users:before {
  content: "\e972";
}