.wrapper {
    .add-prototype, .line, .label {
        color: black;
    }
}

body.dark {
    .wrapper {
        .add-prototype, .line, .label {
            color: white;
        }
    }
}