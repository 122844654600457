.datepicker-inputbox {
  width: 100%;
  font-size: 14px;
  padding: 7px 15px 4px;
  background-color: var(--input-background-color);
  color: var(--text-color);
  height: 38px;
  border: none;
  border-radius: 5px;
  border: 1px solid var(--text-color);
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: var(--text-color);
  }
}
.react-datepicker-wrapper{
  width: 100%;
}
.react-datepicker-popper {
  z-index: 9;
}
.react-datepicker {
  background-color: var(--boxes-color) !important;
  border-color: var(--text-color) !important;
  overflow: hidden;
  border-radius: 7px !important;
}
.react-datepicker__navigation--previous {
  margin-top: 5px;
}
.react-datepicker__navigation--next {
  margin-top: 5px;
}
.react-datepicker__month-container {
  background-color: var(--boxes-color);
  border: none;
}
.react-datepicker__day {
  color: var(--text-color) !important;
  font-size: 12px;
}
.react-datepicker__day:hover,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}
.react-datepicker__day--in-range {
  background-color: var(--primary-color) !important;
}
.react-datepicker__day--in-range:hover {
  color: var(--text-color) !important;
}
.react-datepicker__header {
  background-color: var(--primary-color) !important;
}
.react-datepicker__header {
  border: none;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: var(--primary-color) !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: var(--primary-color) !important;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  border-bottom-color: transparent;
}
.react-datepicker__current-month {
  color: white !important;
  font-size: 14px !important;
  font-family: 'helvetica-regular';
  font-weight: 400 !important;
}
.react-datepicker__day-name {
  color: white !important;
  font-size: 12px !important;
  font-family: 'helvetica-regular';
  font-weight: 400 !important;
}

.react-datepicker-time__caption {
  color: black !important;
}

body.dark {
  .react-datepicker-time__caption {
    color: white !important
  }
}

.react-datepicker__day--in-selecting-range {
  background-color: var(--primary-color) !important;
}
.react-datepicker__day--in-selecting-range:hover {
  color: var(--text-color);
}
.react-datepicker__day--disabled {
  opacity: 0.5;
  &:hover {
    color: $white;
  }
}

.react-datepicker__day--today {
  border-radius: 0.3rem;
  background-color: var(--primary-color-light) !important;
}

.react-datepicker__month--selecting-range
  .react-datepicker__day--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ),
.react-datepicker__year--selecting-range
  .react-datepicker__day--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ),
.react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ),
.react-datepicker__year--selecting-range
  .react-datepicker__month-text--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ),
.react-datepicker__month--selecting-range
  .react-datepicker__quarter-text--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ),
.react-datepicker__year--selecting-range
  .react-datepicker__quarter-text--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ),
.react-datepicker__month--selecting-range
  .react-datepicker__year-text--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ),
.react-datepicker__year--selecting-range
  .react-datepicker__year-text--in-range:not(
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range
  ) {
  background-color: var(--primary-color-light) !important;
  color: #000;
}

.react-datepicker--time-only,
.react-datepicker__time-container {
  .react-datepicker__triangle {
    left: -10px !important;
  }
  .react-datepicker-time__header {
    color: $white;
    font-size: $fs-14;
    font-weight: 400;
  }
  .react-datepicker__time {
    background-color: var(--secondary-700) !important;
    color: $white;
    li {
      &.react-datepicker__time-list-item--selected {
        font-weight: $fw-400 !important;
      }
      &:hover {
        color: var(--theme-color);
      }
    }
  }
}
