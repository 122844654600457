.edit-management {
  position: relative;

  .wrapper {
    width: 100%;
  }

}

.package-modal-wrapper {
  .select__menu-list .select__option:last-child {
    background-color: var(--text-color);
    color: var(--white-color);
  }
}