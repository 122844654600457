// ! Colors

:root {
  --primary-color: #49cdef;
  --primary-color-light: #11cdef2e;
  --white-color: #ffffff;
  --text-color: #18263b;
  --secondary-text-color: #344767;
  --theme-color: #f9f9ff;
  --light-black-color: #333333;
  --input-color: #FFFFFF33;
  --heading-color: #1C2430;
  --secondary-100: #f2f4f7;
  --gray-100: #b2bfd2;
  --gray-200: #8292ad;
  --gray-medium : #8392ab;
  --error-color: #ff0000;
}