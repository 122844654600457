.breadcrumb-main {
  display: flex;
}

.table-responsive-xl {
  overflow-x: auto;
  margin-bottom: 5px;
}

.table-responsive-xl::-webkit-scrollbar {
  width: 0;
  height: 8px;
}

.table-responsive-xl::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

.data-table {
  background-color: var(--boxes-color);
  padding: 25px;
  box-shadow: 0px 10px 20px rgb(178 191 210 / 25%);
  border-radius: 15px;
  position: relative;

  .label {
    color: var(--primary-color);
  }

  .data-table-header {
    margin-bottom: 15px;

    .filter-button {
      display: flex;
      align-items: center;
      font-size: 14px;

      >div {
        transform: translateY(1px);
      }

      .icon-filter-list {
        margin-left: 5px;
      }
    }

    .left .bulk-action+.bulk-action {
      margin-left: 20px;
    }

    .right *+* {
      margin-left: 20px;
    }

    .search-box {
      // width: 206px;
      width: 285px;
      height: 40px;
      border: 1px solid #dfe4ec;
      border-radius: 8px;
      display: flex;
      position: relative;

      .search_icon {
        cursor: pointer;
        padding: 0;
        margin: 0;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 8px;
      }

      input {
        border: none;
        box-shadow: none;
        height: 100%;
        padding-left: 30px;
        margin: 0;
        font-size: $fs-14;
        color: var(--heading-color);
        border: 1px solid transparent;

        &::placeholder {
          color: var(--gray-200);
        }

        &:focus {
          color: var(--heading-color);
          box-shadow: none;
          border-color: var(--primary-color);
        }
      }
    }

    .bulk-action {
      >span {
        font-size: $fs-16;
        color: var(--primary-color);
        font-weight: 500;
      }

      i {
        top: 4px;
      }

      span:not(.select__indicator-separator) {
        margin-right: 9px;
      }
    }
  }

  .dropdown-menu .dropdown-item {
    margin: 0px;
  }

  .data-table-tabs {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid var(--secondary-100);
    overflow-x: auto;

    li {
      font-size: $fs-13;
      color: var(--secondary-400);
      font-weight: $fw-500;
      cursor: pointer;
      min-width: 140px;
      text-align: center;
      border-bottom: 2px solid transparent;
      padding: 3px 5px;

      &.active {
        border-bottom-color: var(--secondary-100);
        font-weight: $fw-600;
        color: var(--secondary-100);
      }
    }
  }

  thead {
    border-bottom: 1px solid var(--secondary-400);
    border-top: 1px solid var(--secondary-400);
    text-transform: uppercase;

    .checkbox {
      width: 65px;
    }

    th {
      font-size: $fs-12;
      line-height: 1.14;
      opacity: 0.7;
      color: var(--secondary-500);
      font-weight: $fw-500;
      font-family: 'Helvetica-Bold';
      padding: 10px 16px;
      vertical-align: middle;
      height: 48px;
      text-transform: capitalize;
      letter-spacing: 0.8px;
      text-align: center;
      text-wrap: nowrap;

      &:nth-child(2) {
        // text-align: left;
      }

      // &:not(:first-child):last-child {
      //   text-align: right;
      // }

      span {
        cursor: pointer;
      }

      i {
        font-size: $fs-20;
        vertical-align: middle;
      }
    }
  }

  tbody {
    border-bottom: 1px solid var(--secondary-100);

    tr {
      &~tr {
        border-top: 1px solid var(--secondary-100);
      }
    }

    tr.deleted-user {
      td {
        color: #e95353;
        font-family: 'Helvetica-Bold';
      }
    }

    td {
      font-size: $fs-14;
      color: var(--secondary-text-color);
      line-height: 1.63;
      text-align: left;
      padding: 16px;
      vertical-align: middle;
      font-family: 'Helvetica-Regular';
      font-weight: $fw-500;
      text-align: center;
      border-bottom: 1px solid var(--secondary-400);

      span.delete-user {
        color: #e95353;
        font-family: 'Helvetica-Bold';
      }

      span.user {
        color: var(--primary-color) !important;
        cursor: pointer;
      }

      &:nth-child(2) {
        // text-align: left;
        text-align: center;
      }

      // &:not(:first-child):last-child {
      //   text-align: right !important;
      // }
    }
  }

  @include media-max(md) {
    thead th {
      height: 40px;
      padding: 5px 10px;
      white-space: nowrap;
    }

    tbody td {
      font-size: $fs-14;
      padding: 10px;
    }

    .data-table-header {
      .right {
        margin-top: 0px !important;
      }
    }

    .data-table-tabs {
      li {
        min-width: 100px;
      }
    }
  }

  @include media-max(sm) {
    .data-table-header {
      .search-box {
        width: auto;
      }
    }
  }
}

body.dark {
  .data-table {
    background-color: var(--boxes-color);
    box-shadow: 0px 0px 20px rgb(196 178 210 / 14%);

    .label {
      color: white;
    }

    .data-table-header {
      .filter-button {
        .icon-filter-list {}
      }

      .left .bulk-action+.bulk-action {}

      .right *+* {}

      .search-box {
        border: 1px solid #353535;

        .search_icon {}

        input {
          color: var(--heading-color);
          box-shadow: 0px 0px 20px 4px rgba(160, 198, 255, 0.09);
          box-shadow: unset;
          background: var(--input-background-color);
          border-color: transparent;

          &::placeholder {
            color: var(--gray-100);
          }

          &:focus {
            color: var(--heading-color);
            box-shadow: none;
            border-color: var(--primary-color);
          }
        }
      }

      .bulk-action {
        >span {
          font-size: $fs-16;
          color: var(--primary-color);
          font-weight: 500;
        }

        i {
          top: 4px;
        }

        span:not(.select__indicator-separator) {
          margin-right: 9px;
        }
      }
    }

    .dropdown-menu .dropdown-item {
      margin: 0px;
    }

    .data-table-tabs {
      border-bottom: 1px solid transparent;

      li {
        color: var(--secondary-400);

        &.active {
          border-bottom-color: var(--secondary-100);
          font-weight: $fw-600;
          color: var(--secondary-100);
        }
      }
    }

    thead {
      border-top: 1px solid var(--secondary-600);
      border-bottom: 1px solid var(--secondary-600);
      text-transform: uppercase;

      .checkbox {
        width: 65px;
      }

      th {
        font-size: $fs-12;
        line-height: 1.14;
        opacity: 0.7;
        color: var(--gray-medium);
        font-weight: $fw-500;
        font-family: 'Helvetica-Bold';
        padding: 10px 16px;
        vertical-align: middle;
        height: 48px;
        text-transform: uppercase;
        text-align: center;

        &:nth-child(2) {
          // text-align: left;
        }

        // &:not(:first-child):last-child {
        //   text-align: right;
        // }

        span {
          cursor: pointer;
        }

        i {
          font-size: $fs-20;
          vertical-align: middle;
        }
      }
    }

    tbody {
      border-bottom: 1px solid var(--secondary-100);

      tr {
        &~tr {
          border-top: 1px solid var(--secondary-100);
        }
      }

      tr.deleted-user {
        td {
          color: #e95353;
        }
      }

      td {
        color: var(--secondary-text-color);
        border-bottom: 1px solid var(--secondary-600);

        span.delete-user {
          color: #e95353;
        }

        span.user {
          color: var(--gray-medium) !important;
          cursor: pointer;
        }

      }
    }

  }
}