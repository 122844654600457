.auth-main {
  min-height: 100vh;
  // padding: 48px;
  display: grid;
  place-items: center;
  position: relative;
  overflow: hidden;
  align-items: start;
  align-content: center;

  .auth-main-background-image {
    width: calc(100% - 2rem);
    min-height: 80vh;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 15px;
    margin-bottom: 64px;
    padding-top: 144px;
    padding-bottom: 160px;
    opacity: 1;
    color: rgb(52, 71, 103);
    background: var(--primary-color);
    border-radius: 12px;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .child-box-second {
    background-color: var(--boxes-color);
    // width: calc(100% - 2rem);
    width: 100%;
    max-width: 432px;
    padding: 37px 24px 37px 24px;
    border-radius: 24px;
    position: relative;
    border-bottom: 10px solid var(--primary-color);
    z-index: 2;
    min-height: 445px;
    transition: 0.3s ease-in-out;
    border-top: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;

    .logo-content {
      display: block;
      text-align: center;

      .auth-logo {
        width: 45%;
        filter: drop-shadow(0 0 8px var(--secondary-text-color));
      }
    }

    &:hover {
      // border-bottom: 10px solid var(--primary-color);
    }

    h1 {
      text-align: center;
      color: var(--primary-color);
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      margin: 0;
    }

    img {
      width: 50px;
      height: auto;
    }

    span {
      margin-left: 20px;
      color: var(--white-color);
      font-size: 20px;
    }

    .line {
      content: '';
      width: 100%;
      height: 1px;
      background: var(--secondary-300);
      display: block;
      margin-bottom: 20px;
    }
  }
}

body.dark {
  .auth-main {
    .child-box-second {
      box-shadow: 0px 0px 20px 3px rgb(156 47 161 / 35%);
      border-bottom: 10px solid var(--primary-color-light);
    }
  }
}