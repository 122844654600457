.invoice-view-modal {
    .modal-dialog {
        box-shadow: 0 0 20px 1px #77777738;
    }

    .modal-header {
        color: var(--text-color);
        padding: 15px 25px;

        button {
            background-color: var(--white-color);
            box-shadow: 0px 0px 30px 0px #2b2b2b50;
            border-radius: 50%;
        }
    }

    .modal-body {
        padding: 8px 25px 20px !important;
        color: var(--text-color);

        .invoice-viewer {
            display: block;
            margin: 5px 0;
            font-size: 14px;
            text-transform: capitalize;

            .line {
                content: '';
                width: 100%;
                height: 1px;
                display: block;
                background: var(--text-color);
            }

            h1,
            h2 {
                color: var(--text-color);
                text-align: justify;
            }

            .invoice-header {
                text-align: justify;

                img {
                    width: 150px;
                }

                h2 {
                    font-size: 13px;
                }
            }

            .payment-header {
                font-weight: 500;
                font-size: 18px;
                text-align: left;
                color: var(--text-color);
            }

            table {
                &.payment-details {
                    tr {
                        border-color: var(--text-color);

                        td {
                            padding: 5px 0;
                            place-items: center;
                        }
                    }
                }

                text-align: justify;

                td {
                    padding: 0px;
                    font-size: 13px;
                }
            }

            .note {
                text-align: justify;

                ul {
                    margin: 0;
                    padding-left: 16px;
                }
            }

            .footer {
                font-size: 13px;
                line-height: 0.5rem;
                margin-top: 5rem;

                p {
                    span {
                        text-transform: lowercase;
                    }

                    strong {
                        font-weight: 800;
                    }
                }
            }

            button {
                border: none;
                border: 1px solid var(--primary-color);

                &:hover {
                    background: var(--white-color);
                    color: var(--white-color);
                }
            }
        }
    }
}