.point-rummy-table {
  .point-rummy-row-delete {
    &:hover {
      i {
        color: var(--error-color);
      }
    }
    i {
      transition: 0.3s ease-in-out;
      cursor: pointer;
      font-size: 18px;
      transform: translateY(-3px);
    }
  }
}

.point-rummy-add-table {
  .entry-fee-input {
    .form-group {
      .form-label {
        margin: 3px 0 11px;
      }
    }
  }
}
