.promotion {
    .wrapper {
        margin-bottom: 15px;
        box-shadow: 0px 0px 20px #312d3621 !important;

        .promotion-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            flex-wrap: wrap;
            margin-bottom: 15px;

            span {
                display: block;
                font-size: 18px;
                color: var(--text-color);
                font-family: 'helvetica-bold';
                font-weight: 700;
            }

            button {
                border-radius: 5px;
                padding: 8px 20px 8px 8px;
                font-size: 14px;

                i {
                    margin-right: 4px;
                }
            }
        }

        .data-table {
            box-shadow: 0px 0px 20px #443b4740 !important;

            .delete-items i:hover {
                color: #f10000 !important;
            }

            .dropdown-datatable {
                .dropdown-datatable-menu {
                    min-width: 125px;

                    .dropdown-datatable-items {

                        &.reschedule-items:hover,
                        &.reschedule-items:hover i {
                            color: #f19900 !important;
                        }

                        &.delete-items:hover,
                        &.delete-items:hover i {
                            color: #f10000 !important;
                        }

                        .dropdown-datatable-items-icon {
                            align-items: center;
                        }

                        .dropdown-datatable-row-text {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

body.dark {
    .promotion {
        .wrapper {
            box-shadow: 0px 0px 20px #b1a5c10f !important;

            .data-table {
                box-shadow: 0px 0px 20px #65586b40 !important;
            }
        }
    }
}