.prototype-name {
    font-size: 16px;
    margin: 6px 0 10px;
    padding: 0 0 5px;
    color: var(--text-color);
    border-bottom: 1px solid;
    border-bottom-color: var(--secondary-400);
}

.prototype-price,
.prototype-seat {
    font-size: 18px;
    color: var(--text-color);
    width: 50%;

    span {
        font-weight: 700;
        font-family: 'helvetica-bold';
        color: var(--secondary-700);
    }
}

.prototype-table {
    margin-bottom: 20px;
    padding: 0;

    .wrapper {
        box-shadow: 0px 10px 20px rgb(193 178 210 / 34%);
    }

    .prototype-table-heading {
        font-size: 16px;
        color: var(--text-color);
        padding: 5px;
    }

    table {
        margin-top: 15px;

        thead {
            border-bottom: 1px solid var(--secondary-400);
            border-top: 1px solid var(--secondary-400);
            text-transform: uppercase;

            tr {
                th {
                    border: none;
                    box-shadow: none;
                    font-size: 12px;
                    line-height: 1.14;
                    opacity: 0.7;
                    color: var(--gray-medium);
                    font-weight: 500;
                    font-family: "Helvetica-Bold";
                    padding: 10px 16px;
                    vertical-align: middle;
                    height: 40px;
                    text-transform: uppercase;
                }
            }
        }

        tbody {
            tr {
                td {
                    min-width: 170px;
                    font-size: 14px;
                    color: var(--secondary-text-color);
                    line-height: 1.63;
                    text-align: left;
                    padding: 10px 16px;
                    vertical-align: middle;
                    font-family: "Helvetica-Regular";
                    font-weight: 500;
                    border-bottom: 1px solid var(--secondary-400);
                }
            }
        }
    }
}

body.dark {

    .prototype-name {
        border-bottom-color: var(--secondary-600);
    }

    .prototype-price,
    .prototype-seat {
        span {
            color: var(--secondary-300);
        }
    }

    .prototype-table {
        .wrapper {
            box-shadow: 0px 10px 20px rgb(193 178 210 / 14%);
        }

        .prototype-table-heading {
            font-size: 16px;
            color: var(--text-color);
            padding: 5px;
        }
    }


    table {
        thead {
            border-top: 1px solid var(--secondary-600);
            border-bottom: 1px solid var(--secondary-600);

            tr {
                th {
                    border: none;
                }
            }
        }

        tbody {
            tr {
                td {
                    color: var(--secondary-text-color);
                    border-bottom: 1px solid var(--secondary-600);
                }
            }
        }
    }
}