::-webkit-scrollbar {
  width: 8px;
}

// /* Track */
::-webkit-scrollbar-track {
  border-radius: 4px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}