.reSchedule-datepicker {
    .rdtPicker {
        background-color: var(--boxes-color);
        color: var(--text-color);
        border: 1px solid var(--text-color);

        thead {
            tr {
                &:first-of-type {
                    th {
                        transition: 0.2s ease-in-out;

                        &:hover {
                            background-color: var(--primary-color);
                            color: var(--secondary-100);
                        }
                    }
                }
            }
        }

        .rdtTime .rdtSwitch {
            border: 1px solid var(--text-color);
            transition: 0.2s ease-in-out;

            &:hover {
                background-color: var(--primary-color);
                color: var(--secondary-100);
            }
        }

        .rdtTimeToggle {
            border: 1px solid var(--text-color);
        }

        .rdtCounter {
            .rdtBtn {
                margin: 1px;
                width: 35px;
                height: 35px;

                &:hover {
                    background-color: var(--primary-color);
                    color: var(--secondary-100);
                }
            }
        }

        td.rdtActive,
        td.rdtActive:hover {
            background-color: var(--primary-color);
            color: var(--secondary-100);
        }

        td.rdtToday {
            &:before {
                border-bottom: 7px solid var(--primary-color);
            }
        }

        td.rdtDay:hover,
        td.rdtHour:hover,
        td.rdtMinute:hover,
        td.rdtSecond:hover,
        .rdtTimeToggle:hover {
            background: var(--primary-color);
            color: var(--secondary-100);
            cursor: pointer;
        }
    }
}

.reSchedule-title {
    color: var(--text-color);
    font-size: 20px;
    padding: 0 0 15px;
}

.reSchedule-form {
    .reSchedule-text {
        font-size: 14px;
        padding: 0 0 6px;
        display: flex;
        align-items: center;
        color: var(--text-color);

        &.text-title {
            font-size: 14px;
            padding: 0;
        }

        &.text-data {
            padding: 0 0 5px;
            font-size: 14px;
            font-weight: 700;
            font-family: 'Helvetica-Bold';
            color: var(--secondary-700);
        }

        @include media-max(sm) {
            &.text-title {
                font-size: 14px;
                padding: 0;
            }

            &.text-data {
                padding: 0 0 5px;
                font-size: 14px;
                font-weight: 700;
                font-family: 'Helvetica-Bold';
                color: var(--secondary-700);
            }
        }

        .reSchedule-submit-button {
            width: 100px;
        }
    }

    input {
        width: 90%;
    }

    .reSchedule-table {
        margin: 15px 0;

        thead {
            background: var(--primary-color-light);
            color: var(--text-color);
            font-size: 14px;
            opacity: 1;
        }

        tbody {
            color: var(--text-color);
            font-size: 14px;
        }
    }
}