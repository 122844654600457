.not-found {
  color: var(--primary-color);
  font-weight: 600;
  width: 100%;
  height: 100%;
  font-size: 100px;
  text-align: center;
  padding: 40px;
  .page_not_found {
    font-size: 50px;
  }
}
