.card-box {
  margin-bottom: 10px;

  svg.dashboard-card-icon-1 {
    background: linear-gradient(130deg, #90adda, #092553) !important;
  }

  svg.dashboard-card-icon-2 {
    background: linear-gradient(310deg, #840000, #ffb0b0) !important;
  }

  svg.dashboard-card-icon-3 {
    background: linear-gradient(310deg, #004829, #67ffcc) !important;
  }

  svg.dashboard-card-icon-4 {
    background: linear-gradient(310deg, #7d5700, #ffcf87) !important;
  }

  svg.dashboard-card-icon-5 {
    background: linear-gradient(310deg, #540094, #f591f9) !important;
  }

  svg.dashboard-card-icon-6 {
    background: linear-gradient(310deg, #524d4d, #e4e4e4) !important;
  }

  svg.dashboard-card-icon-7 {
    background: linear-gradient(310deg, #1171ef, #11cdef) !important;
  }

  svg.dashboard-card-icon-8 {
    background: linear-gradient(310deg, #2dce89, #2dcecc) !important;
  }

  svg.dashboard-card-icon-9 {
    background: linear-gradient(310deg, #eb4a4a, #df70e3) !important;
  }
}

body.dark {
  .card-box {
    margin-bottom: 10px;

    svg.dashboard-card-icon-1 {
      background: linear-gradient(310deg, #0059cc, #1f99a4) !important;
    }

    svg.dashboard-card-icon-2 {
      background: linear-gradient(310deg, #971f37, #ba4828) !important;
    }

    svg.dashboard-card-icon-3 {
      background: linear-gradient(310deg, #1f8f5f, #239997) !important;
    }

    svg.dashboard-card-icon-4 {
      background: linear-gradient(310deg, #a33f28, #b68336) !important;
    }

    svg.dashboard-card-icon-5 {
      background: linear-gradient(310deg, #af4141, #a348a6) !important;
    }

    svg.dashboard-card-icon-6 {
      background: linear-gradient(310deg, #971f37, #ba4828) !important;
    }

    svg.dashboard-card-icon-7 {
      background: linear-gradient(310deg, #0059cc, #1f99a4) !important;
    }

    svg.dashboard-card-icon-8 {
      background: linear-gradient(310deg, #1f8f5f, #239997) !important;
    }

    svg.dashboard-card-icon-9 {
      background: linear-gradient(310deg, #af4141, #a348a6) !important;
    }
  }
}

.dashboard-card-1 {
  .dash-card {
    overflow: hidden;
    height: auto;
    padding: 0px;

    .up-card-1 {
      padding: 25px 25px 0;
    }

    .down-card-1 {
      align-content: space-around;
      padding: 18px 25px !important;
      align-content: space-around;
      margin: 11px 0 0;
      background: linear-gradient(130deg, #90adda, #092553) !important;

      .card-text {
        color: var(--white-color) !important;
      }

      .card-title {
        color: var(--white-color) !important;
      }
    }
  }
}