.game-view-log {
    .seat-number {
        color: var(--text-color);
        font-size: 14px;
        margin: 5px 0;

        span {
            font-family: 'helvetica-bold';
            font-weight: 700;
            font-size: 16px;
        }
    }

    .log-button-group {
        margin-bottom: 15px;

        button {
            background-color: transparent;
            border-radius: 5px;
            margin-right: 15px;
            border: 1px solid transparent !important;
            color: var(--text-color);
            transition: 0.3s ease-in-out;

            &:hover,
            &.active {
                background-color: var(--primary-color);
                color: var(--secondary-100) !important;
                border: 1px solid var(--primary-color) !important;
                // box-shadow: 0px 10px 20px rgba(135, 60, 192, 0.238) !important;
            }
        }
    }

    .game-view-log-select {
        .select__control {
            font-size: 14px;
            background-color: var(--input-background-color);
            color: var(--heading-color);
            height: 38px;
            width: 120px;
            border: 1px solid transparent;
            border-radius: 5px;
            box-shadow: none;
        }

        .select__single-value {
            font-size: $fs-14 !important;
            color: var(--heading-color);
        }

        .select__single-value--is-disabled {
            color: var(--secondary-500);
        }

        .select__menu {
            z-index: 5;
            width: 120px;
            border-bottom-left-radius: 5px;
            overflow: hidden;
            border-bottom-right-radius: 5px;
            background-color: var(--boxes-color);
            box-shadow: 0 0 20px 2px #bcbcbc26;
            border: 1px solid var(--secondary-300);
        }

        .select__menu-list {
            background-color: var(--boxes-color);
            padding: 0;
            z-index: 1;
            text-align: left;

            &::-webkit-scrollbar {
                width: 0;
            }

            .select__option {
                color: var(--text-color);
                font-size: $fs-14;
                background-color: transparent;
                cursor: pointer;

                &:not(.select__option--is-disabled):hover,
                &.select__option--is-selected {
                    background-color: var(--primary-color);
                    color: var(--white-color);
                }

                &:not(.select__option--is-selected).select__option--is-disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }

                &.select__option--is-focused {
                    background-color: transparent;
                    color: var(--text-color);
                }
            }
        }
    }


    .game-view-log-table {
        margin: 15px 0;

        table {
            thead {
                tr {
                    th {

                        &:focus {}

                        &:hover {
                            div {
                                border-top: 1px solid var(--secondary-300);
                                border-bottom: 1px solid var(--secondary-300);
                            }
                        }

                        div {
                            color: var(--gray-medium);
                            font-size: 12px;
                            font-family: 'helvetica-bold';
                            font-weight: 700;
                            text-transform: uppercase;
                            border: none;
                            border-top: 1px solid var(--secondary-300);
                            border-bottom: 1px solid var(--secondary-300);

                            & span {
                                z-index: 2;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-radius: 0;

                    &:hover {
                        background-color: transparent;
                    }

                    th,
                    td {
                        border-top: none;
                        border-radius: 0;
                        background-color: transparent;
                        border-left: transparent;
                        border-right: transparent;

                        &:focus {
                            div {
                                border-color: transparent;
                            }
                        }

                        div {
                            color: var(--text-color);
                            font-size: 14px;

                        }
                    }
                }
            }
        }

        section>div:nth-child(1)>div:nth-child(2)>div {
            padding-bottom: 15px;

            &::-webkit-scrollbar {
                height: 5px;
            }

        }

        section {
            div {

                &:nth-child(2) {
                    justify-content: flex-start;

                    nav {
                        ul {
                            &>li {

                                button {
                                    box-shadow: none;
                                    border: none;
                                    border-radius: 0;
                                    transition: 0.3s ease-in-out;
                                    color: var(--text-color);
                                    background-color: transparent;

                                    &:focus {
                                        background-color: transparent;
                                    }

                                    &:hover {
                                        color: var(--secondary-100);
                                        background-color: var(--primary-color);
                                    }

                                    &:disabled {
                                        color: var(--secondary-300);

                                        &:hover {
                                            color: unset;
                                            background-color: unset;
                                        }
                                    }
                                }
                            }

                            &>div {
                                gap: 0 5px;
                                border-radius: 0;
                                border: none;
                                box-shadow: none;


                                li {

                                    border: none;

                                    button {
                                        border-radius: 0;
                                        color: var(--text-color);
                                        border: none;
                                        transition: 0.3s ease-in-out;
                                        background-color: transparent;
                                        font-size: 14px;

                                        &[aria-current="page"] {
                                            color: var(--secondary-100);
                                            background-color: var(--primary-color);
                                        }

                                        &:focus {
                                            box-shadow: none;
                                        }

                                        &:hover {
                                            color: var(--secondary-100);
                                            background-color: var(--primary-color);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .info-title {
        font-size: 20px;
        color: var(--text-color);
        font-family: 'helvetica-bold';
        font-weight: 700;
        padding-bottom: 10px;
    }

    .view-table-info,
    .view-game-info,
    .view-user-info {
        padding: 25px;
        margin: 0 0 25px;
        box-shadow: 0 0 20px 0px #7d748036;
        border-radius: 15px;

        table {
            font-size: 14px;
            color: var(--text-color);

            thead {
                font-size: 11px;
                font-family: 'helvetica-bold';
                font-weight: 700;
                color: var(--secondary-400);
                text-transform: uppercase;
                border-top: 2px solid var(--secondary-300);
                border-bottom: 2px solid var(--secondary-300);

                tr {
                    th {
                        min-width: 150px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        height: 100%;
                        vertical-align: middle;
                    }
                }
            }
        }

        img {
            width: 50px;
        }

        .view-user-data {
            font-size: 14px;
            color: var(--text-color);
            margin: 20px 0 5px;

            span {
                font-family: 'helvetica-bold';
                font-weight: 700;
                font-size: 15px;
                text-transform: capitalize;

                &.robot {
                    color: rgb(214, 51, 51);
                    margin-right: 5px;
                }
            }
        }


        .card-group {
            display: flex;
            gap: 10px 30px;
            flex-wrap: wrap;
            padding-bottom: 15px;

            .card-sub-group {
                background-color: var(--secondary-200);
                display: flex;
                gap: 0 1px;
                flex-wrap: wrap;
                padding: 5px;
            }
        }
    }
}

body.dark {
    .game-view-log {
        .log-button-group {
            button {

                &:hover,
                &.active {
                    border: 1px solid var(--text-color) !important;
                    box-shadow: 0px 10px 20px rgba(135, 60, 192, 0.157) !important;
                }
            }
        }

        .game-view-log-table {
            table {
                tbody {
                    tr {
                        box-shadow: 0px 1px 1px 0px rgb(215 217 226 / 45%);

                        &:last-child {

                            th,
                            td {
                                border-bottom-color: #675c7d;
                            }
                        }
                    }
                }
            }
        }

        .game-view-log-select {
            .select__menu {
                box-shadow: 0 0 20px 2px #bcbcbc26;
                border: 1px solid var(--secondary-500);
            }
        }

        .view-table-info,
        .view-game-info,
        .view-user-info {
            table {
                thead {
                    border-top-color: var(--secondary-400);
                    border-bottom-color: var(--secondary-400);
                }

                tbody {
                    tr {
                        td {
                            border-color: var(--secondary-500);
                        }
                    }
                }
            }

            box-shadow: 0px 0px 20px 2px #bcbcbc26;

            .card-group {

                .card-sub-group {
                    background-color: var(--secondary-700);
                }
            }
        }
    }
}