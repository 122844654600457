.tooltip-arrow::before {
    border-top-color: var(--primary-color) !important;
}

.tooltip-inner {
    background-color: var(--primary-color);
}

#button-tooltip {
    opacity: 1;
    z-index: 10;
    font-size: 14px;
}

.user:hover {
    cursor: pointer;
    color: var(--primary-color);
}

body {
    &.dark {
        .user:hover {
            cursor: pointer;
            color: var(--primary-color-light);
        }
    }
}